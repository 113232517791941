import { ITableConfig } from '@/base-ui/table'

export const contentTableConfig: ITableConfig = {
  isShowSelection: true,
  propList: [
    { prop: 'id', label: '服务ID' },
    { prop: 'business_name', label: '所属商家' },
    { label: '分类', slotName: 'category' },
    { label: '服务地区', slotName: 'address' },
    { prop: 'name', label: '名称' },
    { prop: 'price', label: '价格' },
    { label: '提成', slotName: 'commission' },
    { label: '更新时间/更新人', slotName: 'updateInfo' },
    { label: '操作', slotName: 'handler' }
  ]
}
