import { IForm } from '@/base-ui/form/types'

export const searchFormConfig: IForm = {
  labelWidth: '20px',
  formItems: [
    {
      field: 'name',
      type: 'input',
      placeholder: '服务名称'
    },
    {
      field: 'business_name',
      type: 'input',
      placeholder: '所属商家'
    },
    {
      field: 'cates',
      type: 'cascader',
      placeholder: '分类',
      otherOptions: {
        props: { label: 'name', value: 'id', children: 'children' }
      }
    },
    {
      field: 'address_id',
      type: 'select',
      placeholder: '服务地区',
      options: []
    }
  ]
}
